import * as React from "react";
import useMediaQuery from "hook/useMediaQuery";
import {
  Box,
  Text,
  SlideFade,
  Stack,
  Link,
  Button,
  Image,
  useColorModeValue,
  Heading,
} from "@chakra-ui/react";
import AboutMe from "components/AboutMe";
import TechStack from "components/TechStack";
import ContactMe from "components/ContactMe";

import svgIcon from "assets/svg/backSVG.svg";
import { FaEnvelope, FaGithub, FaYoutube } from "react-icons/fa";
import LatestArticle from "./latestArticle/LatestArticle";

import YouTubeStats from "./YouTubeStats";
import SlideUpWhenVisible from "hook/SlideUpWhenVisible";

const fluidType = (minFont, maxFont) => {
  let XX = 768 / 100;
  let YY = (100 * (maxFont - minFont)) / (1920 - 768);
  let ZZ = minFont / 16;
  return `calc(${ZZ}rem + ((1vw - ${XX}px) * ${YY}))`;
};

const display = fluidType(70, 144);
const display2 = fluidType(24, 36);
const Home = () => {
  const isLargerThan800 = useMediaQuery(800);

  return (
    <Stack
      as={"main"}
      justifyContent={"center"}
      alignItems="flex-start"
      px={{ base: "5vw", md: "10vw" }}
      mt={{ base: "15vh", md: "22.5vh" }}
    >
      <Stack spacing={10} justifyContent="flex-start" alignItems={"flex-start"}>
        <SlideFade
          in={true}
          transition={{ enter: { duration: 0.4, delay: 0.9 } }}
        >
          <Box position="relative">
            <Image
              src={svgIcon}
              filter="invert(0.1)"
              w={{ base: "70px", md: "150px" }}
              position="absolute"
              top={{ base: "0", md: "-20" }}
              left={{ base: "-5", md: "-10" }}
              zIndex={0}
              alt=""
            />

            <Text
              color="#3CCF91"
              fontSize={display2}
              fontWeight="medium"
              position="relative"
              zIndex={1}
            >
              Hey! 👋 I'm -
            </Text>
          </Box>
          <Heading
            fontSize={display}
            lineHeight={"95%"}
            letterSpacing={{ sm: "-1.2px", md: "-1.8px" }}
            position="relative"
            zIndex={1}
          >
            Shivansh Singh.
          </Heading>
        </SlideFade>
        <SlideFade
          in={true}
          transition={{ enter: { duration: 0.4, delay: 1.2 } }}
        >
          <Heading
            color="#3CCF91"
            fontSize={display2}
            fontWeight="medium"
            whiteSpace={"pre-wrap"}
            letterSpacing="-1px"
          >
            <Box color={useColorModeValue("white", "gray.900")} as="span">
              Flutter Developer &
            </Box>{" "}
            YouTuber{" "}
          </Heading>
          <Heading
            color="#8F9094"
            fontSize={display2}
            fontWeight="medium"
            whiteSpace={"pre-wrap"}
            letterSpacing="-1px"
            pt={2}
          >
            {isLargerThan800
              ? "I create awesome apps and video tutorials for the community."
              : "I create awesome apps\nand video tutorials for the community."}
          </Heading>
        </SlideFade>
        <SlideFade
          in={true}
          transition={{ enter: { duration: 0.4, delay: 1.5 } }}
        >
          <Stack isInline spacing={3}>
            <Link href="https://youtube.com/backslashflutter" isExternal>
              <Button
                backgroundColor={useColorModeValue("gray.800", "gray.200")}
                color={useColorModeValue("white", "gray.800")}
                position={"static"}
                size={isLargerThan800 ? "md" : "sm"}
                leftIcon={<FaYoutube color="#3CCF91" />}
              >
                YouTube
              </Button>
            </Link>
            <Link href="https://github.com/backslashflutter" isExternal>
              <Button
                position={"static"}
                size={isLargerThan800 ? "md" : "sm"}
                backgroundColor={useColorModeValue("gray.800", "gray.200")}
                color={useColorModeValue("white", "gray.800")}
                leftIcon={<FaGithub color="#3CCF91" />}
              >
                Github
              </Button>
            </Link>
            <Link href="mailto:backslash.flutter@gmail.com" isExternal>
              <Button
                position={"static"}
                size={isLargerThan800 ? "md" : "sm"}
                backgroundColor={useColorModeValue("gray.800", "gray.200")}
                color={useColorModeValue("white", "gray.800")}
                leftIcon={<FaEnvelope color="#3CCF91" />}
              >
                Contact Me
              </Button>
            </Link>
          </Stack>
        </SlideFade>
        <SlideUpWhenVisible>
          <AboutMe />

          <Box mt={{ base: "150px", md: "200px" }}>
            <YouTubeStats />
          </Box>

          <Box mt={{ base: "150px", md: "200px" }}>
            <TechStack />
          </Box>
        </SlideUpWhenVisible>

        <Box
          px={{ base: "5vw", md: "9vw" }}
          mt={{ base: "5vh", md: "0vh" }}
          justifyContent="center"
        >
          <LatestArticle />
        </Box>
      </Stack>

      <ContactMe />
    </Stack>
  );
};

export default Home;
