import TopNavigation from "components/TopNav";

import { BrowserRouter as Router } from "react-router-dom";

import Footer from "components/Footer";
import React from "react";
import Navigation from "components/Navigation";
import ContentfulUtil from "hook/Client";
class Layout extends React.Component {
  state = {
    posts: [],
  };

  componentDidMount() {
    ContentfulUtil.fetchBlogPostForHomePage().then(this.setPosts);
  }
  setPosts = (response) => {
    this.setState({
      posts: response.items,
    });
  };

  render() {
    return (
      <Router>
        <TopNavigation />

        <Navigation />

        <Footer />
      </Router>
    );
  }
}

export default Layout;
