import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AboutPage from "../routes/about";
import Projects from "../routes/projects";

import Home from "./Home";

const routes = [
  { path: "/", exact: true, name: "Home", c: Home },
  { path: "/about", exact: true, name: "About", c: AboutPage },
  { path: "/projects", exact: true, name: "Project", c: Projects },
];

const Navigation = () => {
  return (
    <Switch>
      {routes.map((route, index) => (
        <Route
          key={index}
          exact={route.exact}
          path={route.path}
          render={(p) => <route.c />}
        />
      ))}
      <Redirect to="/" />
    </Switch>
  );
};

export default Navigation;
