import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import * as React from "react";
import {
  Link,
  Stack,
  Image,
  Text,
  Divider,
  useColorModeValue,
  useColorMode,
} from "@chakra-ui/react";

import { FaGithub } from "react-icons/fa";

import { SiBuymeacoffee } from "react-icons/si";
export default function Cards({
  imageUrl,
  title,
  desc,
  githubLink,

  projectLink,
}) {
  return (
    <Stack
      _hover={{ shadow: "gray.800" }}
      bg={useColorModeValue("gray.800", "white")}
      borderRadius="10px"
      minH={"320px"}
      shadow={"base"}
      maxH={"500px"}
      maxW={{ base: "600px", md: "520px" }}
      border="1px"
      borderColor={useColorModeValue("gray.900", "gray.200")}
    >
      <Link href={projectLink} isExternal>
        <Image
          width={1250}
          height={600}
          w="auto"
          h="auto"
          src={imageUrl}
          transition="0.3s"
          borderRadius={"10px 10px 0px 0px"}
          alt={"project image"}
        />
        <Stack px={4} py={2}>
          <Stack
            isInline
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Text
              fontSize={"2xl"}
              color={useColorModeValue("gray.200", "black")}
            >
              {title}
            </Text>
            <Stack isInline justifyContent={"flex-end"} spacing={4}>
              {githubLink && (
                <Link href={githubLink} color="white" isExternal>
                  <FaGithub aria-label="github" size={23} />
                </Link>
              )}
              {projectLink && (
                <Link href={projectLink} color="white" isExternal>
                  <SiBuymeacoffee
                    color={"yellow"}
                    aria-label="project link"
                    size={23}
                  />
                </Link>
              )}
            </Stack>
          </Stack>

          <Divider />
          <Text color={"#8F9094"} fontSize={["sm", "md"]}>
            {documentToReactComponents(desc)}
          </Text>
        </Stack>
      </Link>
    </Stack>
  );
}
