import * as contentful from "contentful";

class ContentfulUtil {
  static client = contentful.createClient({
    space: "4i9iov66vv63",
    accessToken: "uj7N-95z5HKIBY41yy53XQm4RKeIm-wdhUlFR0IAlms",
  });
  static fetchBlogPostForHomePage = () =>
    ContentfulUtil.client.getEntries({
      content_type: "shivanshsingh",
      order: 'sys.createdAt',
      limit: 4
    });
  static fetchAllBlogPost = () => ContentfulUtil.client.getEntries({
    content_type: "shivanshsingh",
        
  });
    
}

export default ContentfulUtil;
