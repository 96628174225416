import {
  Image,
  Link,
  Stack,
  Text,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
import { MotionBox } from "components/motion";
import SlideUpWhenVisible from "hook/SlideUpWhenVisible";
import React from "react";

export default function LatestArticleChild({ article }) {
  const articleBackground = useColorModeValue("gray.800", "white");
  const borderColor = useColorModeValue("gray.700", "gray.100");
  const textColor = useColorModeValue("white", "gray.800");
  return (
    <Stack
      spacing={10}
      w="100%"
      display={"block"}
      p={{ md: "10px", base: "0px", lg: "50px" }}
    >
      <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={{ base: 4, md: 4 }}>
        {article.map((article) => (
          <SlideUpWhenVisible>
            <MotionBox whileHover={{ y: -2 }}>
              <Link
                href={"/blog/" + article.fields.slug}
                _hover={{ textDecoration: "none" }}
                _focus={{ outline: "none" }}
                w="100%"
              >
                <Stack
                  direction={"column"}
                  alignItems="flex-start"
                  bg={articleBackground}
                  _hover={{ shadow: "md" }}
                  borderWidth="1px"
                  transition="0.3s"
                  border="1px"
                  borderColor={borderColor}
                  borderRadius="10px"
                  boxShadow="inset 0 0 1px 1px rgba(0, 0, 0, 0.015)"
                  justifyContent="flex-start"
                >
                  <Image
                    src={article.fields.featuredImage.fields.file.url}
                    w="auto"
                    h="auto"
                    width={"auto"}
                    height={"auto"}
                    transition={"0.3s"}
                    borderRadius="10px 10px 0px 0px"
                    alt="project image"
                  />
                  <Stack px={4} py={2}>
                    <Stack
                      isInline
                      justifyContent={"space-between"}
                      alignItems="center"
                    >
                      <Text
                        color={textColor}
                        fontSize={{ base: "md", md: "xl" }}
                        fontWeight="bold"
                        align={"left"}
                        cursor="pointer"
                      >
                        {article.fields.title}
                      </Text>
                    </Stack>
                  </Stack>
                </Stack>
              </Link>
            </MotionBox>
          </SlideUpWhenVisible>
        ))}
      </SimpleGrid>
    </Stack>
  );
}
