import { Heading } from "@chakra-ui/layout";
import { Flex, Link, Stack, Text } from "@chakra-ui/react";
import ContentfulUtil from "hook/Client";
import SlideUpWhenVisible from "hook/SlideUpWhenVisible";
import * as React from "react";
import LatestArticleChild from "./LatestArticleChild";

class LatestArticle extends React.Component {
  state = {
    posts: [],
  };

  componentDidMount() {
    ContentfulUtil.fetchBlogPostForHomePage().then(this.setPosts);
  }
  setPosts = (response) => {
    this.setState({
      posts: response.items,
    });
  };
  render() {
    return (
      <>
        <Stack
          alignItems={"center"}
          justifyContent="center"
          w="100%"
          mt={"50px"}
          display={this.state.posts.length < 2 ? "none" : "block"}
        >
          <SlideUpWhenVisible>
            <Flex
              alignItems={"align-start"}
              justifyContent="space-between"
              mb={{ base: 6, md: 1 }}
            >
              <Heading fontSize={{ base: "xl", md: "2xl" }}>
                Latest Articles
              </Heading>
              <Link href="/blog">
                <Text fontSize={{ base: "sm", md: "md" }} color="#3CCF91">
                  View all articles &rarr;
                </Text>
              </Link>
            </Flex>

            <LatestArticleChild article={this.state.posts} />
          </SlideUpWhenVisible>
        </Stack>
      </>
    );
  }
}

export default LatestArticle;
