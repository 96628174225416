import {
  Text,
  Heading,
  Flex,
  Stack,
  InputGroup,
  Divider,
  SimpleGrid,
} from "@chakra-ui/react";
import ContentfulUtil from "hook/Client";
import * as React from "react";
import Cards from "../components/Cards";
import { MotionBox } from "../components/motion";

class Projects extends React.Component {
  state = {
    projects: [],
  };
  componentDidMount(): void {
    ContentfulUtil.client
      .getEntries({
        content_type: "projects",
      })
      .then(this.setPosts);
  }
  setPosts = (r) => {
    this.setState({
      projects: r.items,
    });
    console.log(this.state.projects);
  };
  render() {
    return (
      <Flex as="main" justifyContent="center" flexDirection="column">
        <Stack
          spacing={10}
          justifyContent="center"
          px={["5vw", "10vw"]}
          my={["5vh", "5vh", "10vh", "10vh"]}
        >
          <Stack spacing={5}>
            {""}
            <Heading color={"#3CCF91"}>Projects</Heading>
            <Text fontSize={{ base: "15px", md: "16px" }}>
              I love exposing myself to latest technologies and practice my
              engineering skills, here's an archive of things that I've worked
              on.
            </Text>
          </Stack>

          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={8}>
            {this.state.projects.map((project) => (
              <MotionBox whileHover={{ y: -4 }}>
                <Cards
                  key={project.fields.title}
                  imageUrl={project.fields.imageUrl.fields.file.url}
                  title={project.fields.title}
                  desc={project.fields.description}
                  githubLink={project.fields.githubLink}
                  projectLink={project.fields.projectLink}
                />
              </MotionBox>
            ))}
          </SimpleGrid>
        </Stack>
      </Flex>
    );
  }
}

export default Projects;
