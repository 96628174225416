import figma from "assets/skills/figma.png";
import vscode from "assets/skills/vscode.png";
import react from "assets/skills/react.png";
import javascript from "assets/skills/javascript.png";
import flutter from "assets/skills/flutter.jpeg";
import python from "assets/skills/python.png";
import mysql from "assets/skills/mysql.png";
import heroku from "assets/skills/heroku.png";
import central from 'assets/logo/central.png';
import appLoop from 'assets/logo/apploop.png';
import backslashflutter from 'assets/logo/backslashflutter.png';
import lu from 'assets/logo/lu.jpeg';


// my companies
export const companies = [
 
  {
    title: "Backslash Flutter",
    alt: "Founder, CTO",
    role: "Founder, CTO",
    skills: ["flutter", "nodejs", "postman", "react"],
    period: "2021-Present",
    logo: backslashflutter
  
  },
  {
    title: "AppLoop Solutions",
    alt: "App Image",
    role: "Flutter Intern",
    skills: ["flutter", "figma", "postman", "api"],
    period: "2021-Dec 2021",
    logo: appLoop
  
  },
   
];

// study 

export const institutes = [
  {
    short_title: "LucknowUniversity",
    title: "Lucknow University",
    alt: "LU IMAGE",
    role: "Bachelor's Degree in Business Administration",
    skills: ["project management", "financial analysis"],
    period: "2020-2023",
    
    logo: lu,
    
  },
  {
    short_title: "CentralAcademy",
    title: "Central Academy Senior Secondary School",
    alt: "CentralAcademy",
    role: "Class 12th",
    skills: ["commerce", "computers"],
    period: "2019-2020",
    
    logo: central,
    
  },
  {
    short_title: "CentralAcademy",
    title: "Central Academy Senior Secondary School",
    alt: "CentralAcademy",
    role: "CLASS 10th",
    skills: ["pcmb & others"],
    period: "2017-2018",
    
    logo: central,
    
  },
];

 

// my skills
export const skills = [
    
    {
      name: "React",
      description: "Web development",
      link: "https://reactjs.org/",
      type: "development",
      image: react
    },
     
    {
      name: "Python",
      description: "Scraping",
      link: "https://www.python.org/",
      type: "development",
      image: python
    },
    {
      name: "Javascript",
      description: "Web development",
      link: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
      type: "development",
      image: javascript
    },
     
    {
      name: "Flutter",
      description: "Web layouts",
      link: "https://flutter.dev",
      type: "design",
      image: flutter
    },
    
     
    {
      name: "Mysql",
      description: "Database",
      link: "https://www.mysql.com/",
      type: "database",
      image: mysql
    },
     
    {
      name: "Heroku",
      description: "Devops",
      link: "https://www.heroku.com/",
      type: "devops",
      image: heroku
    },
    
    
    {
      name: "VS Code",
      description: "Code editor",
      link: "https://code.visualstudio.com/",
      type: "devops",
      image: vscode
    },
    {
      name: "Figma",
      description: "UI/UX",
      link: "https://www.figma.com/",
      type: "design",
      image: figma
    }
  ];
  