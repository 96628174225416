import React from "react";
import { Flex, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import format from "comma-number";
import StatBox from "./StatBox";
var key = "AIzaSyDFEkYbG9DNQnO3SGhWfbbqwRgQ4Nwh_PQ";

var finalUrl =
  "https://www.googleapis.com/youtube/v3/channels?part=statistics&id=UCknAgO0AdG61Yd1G7D1Arxg&key=" +
  key;
class YouTubeStats extends React.Component {
  state = {
    data: [],
    subs: "",
    viewCount: "",
    videoCount: "",
  };
  componentDidMount(): void {
    fetch(finalUrl)
      .then((response) => response.json())
      .then(this.setData);
  }
  setData = (r) => {
    this.setState({
      data: r.items[0].statistics,
      subs: r.items[0].statistics.subscriberCount,
      viewCount: r.items[0].statistics.viewCount,
      videoCount: r.items[0].statistics.videoCount,
    });
  };
  render() {
    return (
      <Flex
        flexDirection={"column"}
        maxWidth="100%"
        alignSelf={[null, "center", "center"]}
      >
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.7, delay: 0.4 }}
        >
          <Heading my="4" size="xl">
            Channel Stats ❤
          </Heading>
          <Text
            color="gray.500"
            fontSize={{ base: "15px", md: "xl" }}
            align={{ base: "left", md: "left" }}
          >
            Here are some of the stats from my channel Backslash Flutter. <br />
            Do subscribe if are interested in learning Flutter App development.
          </Text>
          <SimpleGrid mt={4} minChildWidth={"300px"} spacing="20px">
            <StatBox
              title={`${this.state.subs ? format(this.state.subs) : "–––"}`}
              desc="YouTube Subscribers"
              url="https://youtube.com/backslashflutter"
            />
            <StatBox
              title={`${
                this.state.viewCount ? format(this.state.viewCount) : "–––"
              }`}
              desc="YouTube Views"
              url="https://youtube.com/backslashflutter"
            />
            <StatBox
              title={`${
                this.state.videoCount ? format(this.state.videoCount) : "–––"
              }`}
              desc="Total Uploads"
              url="https://youtube.com/backslashflutter"
            />
          </SimpleGrid>
        </motion.div>
      </Flex>
    );
  }
}

export default YouTubeStats;
