import SlideUpWhenVisible from "hook/SlideUpWhenVisible";
import React from "react";
import svgIcon from "assets/svg/backSVG.svg";
import UserIcon from "assets/images/me.jpeg";

import {
  SimpleGrid,
  Text,
  Stack,
  Heading,
  Image,
  SlideFade,
  Flex,
  Box,
} from "@chakra-ui/react";

const AboutMe = () => {
  return (
    <>
      <SlideUpWhenVisible>
        <SlideFade
          in={true}
          transition={{ enter: { duration: 0.4, delay: 2 } }}
        >
          <Box mt={{ base: "100px", md: "200px" }}>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
              <SlideUpWhenVisible>
                <Stack spacing={4}>
                  <Heading fontSize="2xl">About Me</Heading>
                  <Text
                    color="#8F9094"
                    whiteSpace={"pre-line"}
                    fontSize={{ base: "14px", md: "16px" }}
                  >
                    Hey! I'm Shivansh Singh, the guy behind Backslash Flutter.
                    😉 <br />
                    <br />
                    Before starting Flutter development, I was working as
                    freelance web developer and have created many websites for
                    my clients.
                    <br></br> But things took a whole new turn after I started
                    Flutter development. On my channel I publish awesome flutter
                    tutorials and also share dev tips/tricks.
                  </Text>
                </Stack>
              </SlideUpWhenVisible>
              <SlideUpWhenVisible>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  position="relative"
                >
                  <Box
                    maxW={{ base: "300px", lg: "350px" }}
                    maxH={{ base: "300px", lg: "350px" }}
                  >
                    <Image
                      src={svgIcon}
                      filter="invert(0.1)"
                      zIndex={3}
                      position="absolute"
                      top={0}
                      right={0}
                      w={{ base: "100px", lg: "150px" }}
                      alt=""
                    />
                    <Image
                      alt="Shivansh Singh"
                      borderRadius="full"
                      src={UserIcon}
                      w={{ base: "200px", lg: "300px" }}
                      h={{ base: "200px", lg: "300px" }}
                    />
                  </Box>
                </Flex>
              </SlideUpWhenVisible>
            </SimpleGrid>
          </Box>
        </SlideFade>
      </SlideUpWhenVisible>
    </>
  );
};

export default AboutMe;
