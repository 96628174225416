import React from "react";
import {
  Stack,
  Text,
  Link,
  Button,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";
import { Heading } from "@chakra-ui/react";
import SlideUpWhenVisible from "hook/SlideUpWhenVisible";
import { FaEnvelope, FaGithub, FaYoutube } from "react-icons/fa";
import useMediaQuery from "hook/useMediaQuery";

export default function ContactMe() {
  const isLargerThan800 = useMediaQuery(800);
  return (
    <>
      <Stack
        spacing={10}
        h="70vh"
        w="100%"
        alignItems={"center"}
        justifyContent="center"
      >
        <SlideUpWhenVisible>
          <Heading
            fontSize={{ base: "4xl", md: "5xl", lg: "6xl" }}
            textAlign="center"
          >
            Keep in Touch.
          </Heading>
        </SlideUpWhenVisible>

        <SlideUpWhenVisible>
          <Box p={1}>
            <Text
              fontSize={{ base: "lg" }}
              color="#8F9094"
              textAlign={"center"}
            >
              I'm currently open to freelance work. Feel free to contact me over
              email.
            </Text>
          </Box>
        </SlideUpWhenVisible>
        <SlideUpWhenVisible>
          <Stack isInline spacing={3}>
            <Link href="https://youtube.com/backslashflutter" isExternal>
              <Button
                position={"static"}
                size={isLargerThan800 ? "md" : "sm"}
                backgroundColor={useColorModeValue("gray.800", "gray.200")}
                color={useColorModeValue("white", "gray.800")}
                leftIcon={<FaYoutube color="#3CCF91" />}
              >
                YouTube
              </Button>
            </Link>
            <Link href="https://github.com/backslashflutter" isExternal>
              <Button
                position={"static"}
                size={isLargerThan800 ? "md" : "sm"}
                backgroundColor={useColorModeValue("gray.800", "gray.200")}
                color={useColorModeValue("white", "gray.800")}
                leftIcon={<FaGithub color="#3CCF91" />}
              >
                Github
              </Button>
            </Link>
            <Link href="mailto:backslash.flutter@gmail.com" isExternal>
              <Button
                position={"static"}
                size={isLargerThan800 ? "md" : "sm"}
                backgroundColor={useColorModeValue("gray.800", "gray.200")}
                color={useColorModeValue("white", "gray.800")}
                leftIcon={<FaEnvelope color="#3CCF91" />}
              >
                Contact Me
              </Button>
            </Link>
          </Stack>
        </SlideUpWhenVisible>
      </Stack>
    </>
  );
}
