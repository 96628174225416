import React from "react";
import {
  FaGithub,
  FaYoutube,
  FaLinkedin,
  FaInstagram,
  FaTwitter,
} from "react-icons/fa";
import { FiMail } from "react-icons/fi";

const siteConfig = {
  copyright: `Copyright © ${new Date().getFullYear()} Shivansh Singh. All Rights Reserved.`,
  author: {
    name: "Shivansh Singh",
    accounts: [
      {
        url: "https://github.com/backslashflutter",
        label: "Github Account",
        type: "black",
        icon: <FaGithub />,
      },
      {
        url: "https://twitter.com/shivanshsinghh_",
        label: "Twitter Account",
        type: "twitter",
        icon: <FaTwitter />,
      },

      {
        url: "https://linkedin.com/in/muhammad-ahmad20",
        label: "LinkedIn Account",
        type: "linkedin",
        icon: <FaLinkedin />,
      },
      {
        url: "https://youtube.com/backslashflutter",
        label: "YouTube Account",
        type: "red",
        icon: <FaYoutube />,
      },
      {
        url: "https://instagram.com/shivanshsinghh_",
        label: "Instagram Account",
        type: "pink",
        icon: <FaInstagram />,
      },
      {
        url: "mailto:backslash.flutter@gmail.com",
        label: "Backslash Flutter",
        type: "green",
        icon: <FiMail />,
      },
    ],
  },
};

export default siteConfig;
