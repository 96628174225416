import {
  Box,
  Stack,
  VStack,
  Heading,
  Flex,
  Text,
  Tag,
  Image,
  useColorMode,
  useColorModeValue,
  SimpleGrid,
  color,
} from "@chakra-ui/react";
import svgIcon from "assets/svg/backSVG.svg";
import UserIcon from "assets/images/me.jpeg";

import Header from "../components/Header";
import React from "react";
import placeHolder from "assets/logo/placeholder.png";
import { MotionBox } from "../components/motion";
import {
  CardTransition,
  PageSlideFade,
  StaggerChild,
} from "../components/PageTransition";
import { BsFillBriefcaseFill } from "react-icons/bs";
import { companies, institutes } from "data/data";
import { FaGraduationCap, FaHandPeace } from "react-icons/fa";
import SlideUpWhenVisible from "hook/SlideUpWhenVisible";
import { MdWavingHand } from "react-icons/md";

interface CardProps {
  title: string;
  role: string;
  skills: string[];
  period: string;
  logo: string;
  colorMode: string;
  alt: string;
}
const Card = (props: CardProps) => {
  const backgroundColor = useColorModeValue("gray.200", "gray.500");
  const { title, role, skills, period, logo, colorMode, alt } = props;
  return (
    <CardTransition>
      <Box
        px={4}
        py={5}
        borderWidth="1px"
        borderColor={useColorModeValue("gray.900", "gray.200")}
        _hover={{ shadow: "lg" }}
        bg={useColorModeValue("gray.800", "white")}
        position="relative"
        rounded="md"
      >
        <Flex justifyContent="space-between">
          <Flex>
            <Image
              rounded="full"
              w={16}
              h={16}
              objectFit="cover"
              fallbackSrc={placeHolder}
              src={logo}
              alt={alt}
            />
            <Stack spacing={2} pl={3} align="left">
              <Heading fontSize="xl" color={`mode.${colorMode}.career.text`}>
                {title}
              </Heading>
              <Heading
                fontSize={{ base: "12px", md: "sm" }}
                color={`mode.${colorMode}.career.subtext`}
              >
                {role}
              </Heading>
              <Stack
                spacing={1}
                mt={3}
                isInline
                alignItems="center"
                display={["none", "none", "flex", "flex"]}
              >
                {skills.map((skill) => (
                  <Tag size="sm" padding="0 3px" key={skill}>
                    {skill}
                  </Tag>
                ))}
              </Stack>
            </Stack>
          </Flex>

          <Text fontSize={14} color={`mode.${colorMode}.career.subtext`}>
            {period}
          </Text>
        </Flex>
        <Stack
          spacing={1}
          mt={3}
          isInline
          alignItems="center"
          display={["flex", "flex", "none", "none"]}
        >
          {skills.map((skill) => (
            <Tag size="sm" padding="0 3px" key={skill} bg={backgroundColor}>
              {skill}
            </Tag>
          ))}
        </Stack>
      </Box>
    </CardTransition>
  );
};

const AboutPage = () => {
  const UNDERLINE_COLOR = useColorModeValue("#06b6d4", "#06b6d4");
  const { colorMode } = useColorMode();
  return (
    <PageSlideFade>
      <StaggerChild>
        <MotionBox>
          <Flex alignItems={"center"} p={4} mt={4}>
            <Header
              underlineColor={UNDERLINE_COLOR}
              mt={0}
              mb={0}
              px={{ base: 0, md: 10 }}
            >
              Hi There!
            </Header>
            <Stack pl={3}>
              <Box>
                <MdWavingHand size={"25px"} />{" "}
              </Box>
            </Stack>
          </Flex>
          <Box mt={{ base: "10px", md: "10px" }} p={{ base: 5, md: 4 }}>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
              <SlideUpWhenVisible>
                <Stack spacing={4}>
                  <Text
                    px={{ base: 0, md: 10 }}
                    color="#8F9094"
                    whiteSpace={"pre-line"}
                    fontSize={{ base: "14px", md: "16px" }}
                  >
                    Hey! I'm Shivansh Singh 😉 <br />
                    <br />
                    So now you have made to the about us page this means you are
                    interested in my story. I am just a simple guy with love for
                    technologies since age 10. Initially I have worked on
                    various other side projects and freelance works but now I
                    focus more on app development.
                    <br></br> You can join me on{" "}
                    <a href="https://youtube.com/backslashflutter">
                      @backslashflutter
                    </a>{" "}
                    and there you will learn a lot of new stuff with Flutter App
                    Development. I teach authentication, design procedure, state
                    managmenet and much more. <br></br>I am open to all open
                    source work and collaboration. So do let me know if you have
                    anything special for me.
                  </Text>
                </Stack>
              </SlideUpWhenVisible>
              <SlideUpWhenVisible>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  position="relative"
                >
                  <Box
                    maxW={{ base: "300px", lg: "350px" }}
                    maxH={{ base: "300px", lg: "350px" }}
                  >
                    <Image
                      src={svgIcon}
                      filter="invert(0.1)"
                      zIndex={3}
                      position="absolute"
                      top={0}
                      right={0}
                      w={{ base: "100px", lg: "150px" }}
                      alt=""
                    />
                    <Image
                      alt="Shivansh Singh"
                      borderRadius="full"
                      src={UserIcon}
                      w={{ base: "200px", lg: "300px" }}
                      h={{ base: "200px", lg: "300px" }}
                    />
                  </Box>
                </Flex>
              </SlideUpWhenVisible>
            </SimpleGrid>
          </Box>

          <Heading>
            <Flex alignItems={"center"} p={4}>
              <Header
                underlineColor={UNDERLINE_COLOR}
                mt={0}
                mb={0}
                px={{ base: 0, md: 10 }}
              >
                Career
              </Header>
              <Stack pl={3}>
                <Box as={BsFillBriefcaseFill} size="25px" />
              </Stack>
            </Flex>
          </Heading>
        </MotionBox>
        <Box w={["90%", "85%", "80%"]} maxW={800} mx="auto">
          <VStack
            spacing={4}
            marginBottom={6}
            align="left"
            mx={[0, 0, 6]}
            p={2}
            mt={12}
          >
            {companies.map((company, index) => (
              <MotionBox whileHover={{ y: -4 }} key={index}>
                <Card
                  alt={company.alt}
                  key={index}
                  title={company.title}
                  role={company.role}
                  skills={company.skills}
                  period={company.period}
                  logo={company.logo}
                  colorMode={colorMode}
                />
              </MotionBox>
            ))}
          </VStack>
          <Heading>
            <Flex alignItems={"center"} p={4}>
              <Header underlineColor={UNDERLINE_COLOR} mt={0} mb={0}>
                Education
              </Header>
              <Stack pl={3}>
                <Box as={FaGraduationCap} size="25px" />
              </Stack>
            </Flex>
          </Heading>
          <VStack
            spacing={4}
            marginBottom={6}
            align="left"
            p={2}
            mx={[0, 0, 6]}
            mt={12}
          >
            {institutes.map((institute, index) => (
              <MotionBox whileHover={{ y: -5 }} key={index}>
                <Card
                  alt={institute.alt}
                  key={index}
                  title={institute.title}
                  role={institute.role}
                  skills={institute.skills}
                  period={institute.period}
                  logo={institute.logo}
                  colorMode={colorMode}
                />
              </MotionBox>
            ))}
          </VStack>
        </Box>
      </StaggerChild>
    </PageSlideFade>
  );
};

export default AboutPage;
