import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";

import * as React from "react";
import { ColorModeSwitcher } from "ColorModeSwitcher";
import { NavLink as RouterNavLink } from "react-router-dom";
import {
  Box,
  Flex,
  HStack,
  Text,
  Link,
  IconButton,
  useDisclosure,
  useColorModeValue,
  Stack,
} from "@chakra-ui/react";

const webRoutes = [
  { name: "Home", path: "/" },
  { name: "Blog", path: "/blog" },
  { name: "Projects", path: "/projects" },
  { name: "About", path: "/about" },
];

const webRoutesForMobile = [
  { name: "Home", path: "/" },
  { name: "Blog", path: "/blog" },
  { name: "Projects", path: "/projects" },
  { name: "About", path: "/about" },
];

interface NavLinkProps {
  index?: number;
  name: string;
  path: string;
  onClose: () => void;
}

const NavLink = (props: NavLinkProps) => {
  return (
    <Link
      to={props.path}
      onClick={() => props.onClose()}
      as={RouterNavLink}
      px={2}
      _hover={{
        textDecoration: "none",
        bg: useColorModeValue("gray.900", "gray.200"),
      }}
      _activeLink={{
        color: useColorModeValue("#3CCF91", "#3CCF91"),
      }}
      py={1}
      rounded={"md"}
    >
      {props.name}
    </Link>
  );
};

export default function TopNavigation() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box
      position={"sticky"}
      bg={useColorModeValue("#0C0D12", "whiteAlpha.700")}
      px={2}
      shadow="xs"
    >
      <Flex
        h={16}
        alignItems={"center"}
        justifyContent={"space-between"}
        w={["90%", "85%", "80%"]}
        maxW={800}
        mx="auto"
      >
        <HStack spacing={8} alignItems={"center"}>
          <Link href="/">
            <Box>
              <Text
                color={useColorModeValue("gray.200", "#3CCF91")}
                fontWeight="bold"
                as={Link}
                size={"sm"}
              >
                S/S.
              </Text>
            </Box>
          </Link>
        </HStack>

        <Flex alignItems={"center"}>
          <HStack as={"nav"} spacing={4} display={{ base: "none", md: "flex" }}>
            {webRoutes.map((link, index) => (
              <NavLink
                key={index}
                name={link.name}
                path={link.path}
                onClose={onClose}
              />
            ))}
          </HStack>
          <Box mr={1}>
            <ColorModeSwitcher justifySelf={"flex-end"} />
          </Box>
          <IconButton
            _hover={{
              bg: useColorModeValue("gray.900", "gray.100"),
            }}
            bg={useColorModeValue("gray.900", "gray.200")}
            aria-label={"Open Menu"}
            display={["inherit", "inherit", "none"]}
            size={"md"}
            onClick={isOpen ? onClose : onOpen}
            icon={isOpen ? <AiOutlineClose /> : <GiHamburgerMenu />}
          />
        </Flex>
      </Flex>
      {isOpen ? (
        <Box
          pb={4}
          w={["100%", "100%", "80%"]}
          maxW={800}
          display={["inherit", "inherit", "none"]}
        >
          <Stack as={"nav"} spacing={4}>
            {webRoutesForMobile.map((link, index) => (
              <NavLink
                index={index}
                name={link.name}
                path={link.path}
                onClose={onClose}
              />
            ))}
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
}
