import * as React from "react";
import {
  Flex,
  Box,
  Link,
  StatGroup,
  Stat,
  StatLabel,
  StatNumber,
  useColorMode,
} from "@chakra-ui/react";

export default function StatBox({ title, desc, url }) {
  const { colorMode } = useColorMode();
  const borderColor = {
    light: "#CBD5E0",
    dark: "#4A5568",
  };
  const [opacity, setOpacity] = React.useState(0);
  return (
    <Link
      isExternal
      _hover={{ textDecoration: "none" }}
      onMouseOver={() => setOpacity(1)}
      onMouseLeave={() => setOpacity(0)}
    >
      <Box p={2} pb={[2, 1, 1]}>
        <StatGroup
          border={`1px solid ${borderColor[colorMode]}`}
          borderRadius={5}
          p={2}
        >
          <Stat>
            <Flex align={"center"} justifyContent="space-between">
              <StatLabel>{desc}</StatLabel>
            </Flex>
            <StatNumber>{title}</StatNumber>
          </Stat>
        </StatGroup>
      </Box>
    </Link>
  );
}
