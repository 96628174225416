import * as React from "react";
import { useState } from "react";
import {
  Icon,
  VStack,
  HStack,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Stack,
  Tab,
  TabPanel,
  useColorModeValue,
  SimpleGrid,
} from "@chakra-ui/react";
import Header from "./Header";
import { skills } from "data/data";
import Section from "./Section";
import { container } from "./PageTransition";
import { AiFillFire } from "react-icons/ai";
import { RiComputerFill } from "react-icons/ri";
import { MdOutlineDesignServices } from "react-icons/md";
import { ImBug } from "react-icons/im";
import { MotionBox } from "./motion";
import SkillCard from "./SkillCard";

const TechStack = () => {
  const [skillLists, setSkillsList] = useState([]);

  const filterSkills = (tab) => {
    if (tab.length) setSkillsList(skills.filter((skill) => skill.type === tab));
    else setSkillsList(skills);
  };
  React.useEffect(() => {
    setSkillsList(skills);
  }, []);

  return (
    <Stack spacing={10} w="100%" alignItems="center" justifyContent="center">
      <VStack>
        <VStack spacing={8}>
          <Header
            mt={0}
            mb={2}
            underlineColor={useColorModeValue("#3CCF91", "#0C0D12")}
          >
            Tech Stack
          </Header>

          <Text
            textAlign={"center"}
            maxW="lg"
            fontSize={"xl"}
            color={useColorModeValue("gray.200", "gray.500")}
          >
            I have experience in using these tools and technologies.
          </Text>
        </VStack>

        <Section>
          <Tabs
            variant="soft-rounded"
            colorScheme="blue"
            align="center"
            w="100%"
          >
            <TabList display="flex" flexWrap="wrap">
              <Tab
                bg={useColorModeValue("gray.800", "gray.200")}
                color={useColorModeValue("gray.500", "gray.600")}
                _selected={{
                  color: "green.800",
                  bg: "green.100",
                }}
                mr={2}
                mt={2}
                onClick={() => filterSkills("")}
              >
                <HStack spacing={1}>
                  <Icon as={AiFillFire} />
                  <Text>All</Text>
                </HStack>
              </Tab>
              <Tab
                bg={useColorModeValue("gray.800", "gray.100")}
                color={useColorModeValue("gray.500", "gray.500")}
                _selected={{
                  color: useColorModeValue("gray.800", "gray.100"),
                  bg: useColorModeValue("gray.100", "gray.900"),
                }}
                mr={2}
                mt={2}
                onClick={() => filterSkills("development")}
              >
                <HStack spacing={1}>
                  <Icon as={RiComputerFill} />
                  <Text>Web Development</Text>
                </HStack>
              </Tab>
              <Tab
                bg={useColorModeValue("gray.800", "gray.100")}
                color={useColorModeValue("gray.500", "gray.600")}
                _selected={{
                  color: "green.800",
                  bg: "green.100",
                }}
                mr={2}
                mt={2}
                onClick={() => filterSkills("design")}
              >
                <HStack spacing={1}>
                  <Icon as={MdOutlineDesignServices} />
                  <Text>App & Design</Text>
                </HStack>
              </Tab>
              <Tab
                bg={useColorModeValue("gray.800", "gray.100")}
                color={useColorModeValue("gray.500", "gray.600")}
                _selected={{
                  color: "red.800",
                  bg: "red.100",
                }}
                mr={2}
                mt={2}
                onClick={() => filterSkills("devops")}
              >
                <HStack spacing={1}>
                  <Icon as={ImBug} />
                  <Text>Devops</Text>
                </HStack>
              </Tab>
            </TabList>
            <TabPanels minHeight={"80vh"}>
              <TabPanel px={0}>
                <MotionBox
                  variants={container}
                  initial="hidden"
                  animate="visible"
                >
                  <SimpleGrid columns={[1, 1, 2]} spacing={4} mt={8}>
                    {skillLists.map((tool, index) => (
                      <SkillCard
                        key={index}
                        name={tool.name}
                        description={tool.description}
                        image={tool.image}
                        link={tool.link}
                      />
                    ))}
                  </SimpleGrid>
                </MotionBox>
              </TabPanel>
              <TabPanel px={0}>
                <MotionBox
                  variants={container}
                  initial="hidden"
                  animate="visible"
                >
                  <SimpleGrid columns={[1, 2]} spacing={4} mt={8}>
                    {skillLists.map((tool, index) => (
                      <SkillCard
                        key={index}
                        name={tool.name}
                        description={tool.description}
                        image={tool.image}
                        link={tool.link}
                      />
                    ))}
                  </SimpleGrid>
                </MotionBox>
              </TabPanel>
              <TabPanel px={0}>
                <MotionBox
                  variants={container}
                  initial="hidden"
                  animate="visible"
                >
                  <SimpleGrid columns={[1, 2]} spacing={4} mt={8}>
                    {skillLists.map((tool, index) => (
                      <SkillCard
                        key={index}
                        name={tool.name}
                        description={tool.description}
                        image={tool.image}
                        link={tool.link}
                      />
                    ))}
                  </SimpleGrid>
                </MotionBox>
              </TabPanel>
              <TabPanel px={0}>
                <MotionBox
                  variants={container}
                  initial="hidden"
                  animate="visible"
                >
                  <SimpleGrid columns={[1, 2]} spacing={4} mt={8}>
                    {skillLists.map((tool, index) => (
                      <SkillCard
                        key={index}
                        name={tool.name}
                        description={tool.description}
                        image={tool.image}
                        link={tool.link}
                      />
                    ))}
                  </SimpleGrid>
                </MotionBox>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Section>
      </VStack>
    </Stack>
  );
};

export default TechStack;
