import * as React from "react";
import {
  Stack,
  IconButton,
  Link,
  Box,
  Text,
  useColorModeValue,
  Flex,
} from "@chakra-ui/react";
import siteConfig from "config/site-config";

const iconProps = {
  variant: "ghost",
  size: "lg",
  isRound: true,
};
const Footer = () => {
  return (
    <Stack
      as="footer"
      isInline
      spacing={[1, 2]}
      p={4}
      mx="auto"
      justifyContent="space-between"
      alignItems={"center"}
    >
      <Flex
        flexDirection={["column", "column", "row"]}
        flexFlow={["column-reverse", "column-reverse"]}
        justifyContent={["center", "space-between"]}
        alignItems="center"
        w="100%"
      >
        <Text
          textAlign={"center"}
          fontSize="sm"
          color={useColorModeValue("gray.200", "gray.500")}
        >
          ©{new Date().getFullYear()} Shivansh Singh
        </Text>
        <Box fontSize="md" textAlign="left">
          Built with
          <Box
            as="span"
            mx="2"
            _before={{
              cursor: "default",
              content: '"❤️ "',
            }}
          />
          in React & Chakra UI{"  "}
        </Box>
        <Box textAlign={"center"}>
          {siteConfig.author.accounts.map((sc, index) => (
            <IconButton
              key={index}
              as={Link}
              isExternal
              href={sc.url}
              aria-label={sc.label}
              size="lg"
              colorScheme={sc.type}
              icon={sc.icon}
              {...iconProps}
            />
          ))}
        </Box>
      </Flex>
    </Stack>
  );
};

export default Footer;
