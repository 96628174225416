import * as React from "react";
import {
  useColorMode,
  useColorModeValue,
  IconButton,
  IconButtonProps,
  Tooltip,
} from "@chakra-ui/react";
import { FaMoon, FaSun } from "react-icons/fa";

type ColorModeSwitcherProps = Omit<IconButtonProps, "aria-label">;

export const ColorModeSwitcher: React.FC<ColorModeSwitcherProps> = (props) => {
  const { toggleColorMode } = useColorMode();
  const text = useColorModeValue("dark", "light");
  const SwitchIcon = useColorModeValue(FaSun, FaMoon);

  const handleClick = () => {
    toggleColorMode();
  };
  return (
    <Tooltip
      label={text === "dark" ? "Light mode" : "Dark mode"}
      aria-label="A tooltip"
    >
      <IconButton
        bg={useColorModeValue("gray.900", "gray.200")}
        size={"md"}
        fontSize={"md"}
        variant={"ghost"}
        color={"current"}
        marginLeft={"2"}
        onClick={handleClick}
        icon={<SwitchIcon />}
        aria-label={`Switch to ${text} mode`}
        _hover={{
          bg: useColorModeValue("gray.900", "gray.100"),
        }}
      />
    </Tooltip>
  );
};
